<template>
  <!-- TODO: Selection tiles on ProductsOverview root? Ie, see the selection tiles after selecting "Products" sidebar, and then click into a product type listings report -->
  <div>
    <PageTitlebar
      :title="
        productType == 'generic' ? 'All Products' : capitalizeText(productType)
      "
      :title-icon="getAssetIcon(productType)"
    >
      <template v-slot:sub-title>
        View the {{ productType }} you've connected to the network.
      </template>
    </PageTitlebar>

    <DataTable
      :table-key="tableKey"
      row-key="product_id"
      title=""
      :columns="columns"
      :rows="products"
      :loading="loading"
      :pagination="pagination"
      :downloadable="false"
      :refreshable="true"
      :customizable="true"
      :filterable="true"
      :server-side="true"
      :searchable="true"
      :disable-state="false"
      :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
      @update-rows="val => getProducts(val)"
    >
      <template v-slot:advertiser_account_name="row">
        <p>{{ row.advertiser_account_name }}</p>
        <p class="text-gray-medium" style="line-height: 1">
          <small>{{ row.advertiser_account_id }}</small>
        </p>
      </template>

      <template v-slot:inventory_source_name="row">
        <p>{{ row.inventory_source_name }}</p>
        <p class="text-gray-medium" style="line-height: 1">
          <small>{{ row.inventory_source_id }}</small>
        </p>
      </template>

      <template v-slot:image_url="row">
        <a :href="row.images[0]" target="_blank">
          {{
            row.images[0].length > 40
              ? row.images[0].substr(0, 40) + "..."
              : row.images[0]
          }}
        </a>
      </template>
      <template v-slot:destination_url_raw="row">
        <a
          v-if="row.destination_url_raw.length > 0"
          :href="row.destination_url_raw"
          target="_blank"
        >
          {{
            row.destination_url_raw.length > 40
              ? row.destination_url_raw.substr(0, 40) + "..."
              : row.destination_url_raw
          }}
        </a>
        <span v-else>-</span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import axios from "axios";
import DataTable from "@/components/UI/DataTable";
import DataTableMixin from "@/mixins/DataTableMixin";
import index from "@/mixins/index";
import { QImg } from "quasar";

export default {
  name: "AbstractProductOverview",
  mixins: [DataTableMixin, index],
  components: {
    DataTable,
    PageTitlebar
  },
  props: {
    productType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      tableKey: this.productType,
      products: [],
      pendingGetRequests: [],
      pagination: {
        sortBy: "product_id",
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 1000
      },
      dimensionFilterOptionsByColumn: {},
      columns: this.getColumnsForProductType()
    };
  },
  beforeMount() {
    // Account for possible advertiser account id / inventory source id initial filters params
    new URLSearchParams(window.location.search);

    let urlParamDataTableState = this.loadDataTableStateFromURLParam(
      this.tableKey,
      this.columns
    );

    if (this.$route.query.inventory_source_id > 0) {
      let sourceIdColumnIndex = urlParamDataTableState.columns.findIndex(
        c => c.name === "inventory_source_id"
      );
      urlParamDataTableState.columns[sourceIdColumnIndex].activeFilterCount = 1;
      urlParamDataTableState.columns[sourceIdColumnIndex].filterValues = {
        in: [this.$route.query.inventory_source_id]
      };
    }

    this.columns = urlParamDataTableState.columns;

    if (Object.keys(urlParamDataTableState.pagination).length > 0) {
      this.pagination = urlParamDataTableState.pagination;
    }
    if (urlParamDataTableState.searchFilter.length > 0) {
      this.searchFilter = urlParamDataTableState.searchFilter;
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts(dataTableState = null) {
      this.loading = true;

      // If we dont have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Abort any existing requests
      if (this.pendingGetRequests.length > 0) {
        this.pendingGetRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingGetRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingGetRequests.push(controller);

      // Build our sort
      const sortParams = [];
      if (dataTableState.pagination.sortBy) {
        sortParams.push({
          [dataTableState.pagination.sortBy]: dataTableState.pagination
            .descending
            ? "desc"
            : "asc"
        });
      }

      // Configure current request filter params
      const filterParams = {};
      const optionParams = {
        faceting: {
          fields: {}
        }
      };

      // Account for search filter
      if (dataTableState.searchFilter.length > 2) {
        optionParams["keyword_search"] = {
          keyword: dataTableState.searchFilter,
          fields: [
            "title_raw^4",
            "description",
            "make_raw^2",
            "model_raw^3",
            "exterior_color_raw",
            "product_id",
            "vin"
          ]
        };
      }

      // Set our response fields
      optionParams["response_fields"] = this.columns.map(c => c.name);

      // Lets handle our facet fields
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .forEach(column => {
          optionParams["faceting"]["fields"][column.name] = [];
        });
      optionParams["faceting"]["size"] = 1200;

      // Account for all column filters dynamically
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .forEach((column, index) => {
          if (column.activeFilterCount > 0) {
            filterParams[index] = {
              [column.name]: { in: column.filterValues.in }
            };
          }
        });

      dataTableState.columns
        .filter(c => c.type === "metric")
        .forEach((column, index) => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.gt.length > 0) {
              filterParams[index] = {
                [column.name]: { gte: +column.filterValues.gt + 1 }
              };
            }
            if (column.filterValues.lt.length > 0) {
              filterParams[index] = {
                [column.name]: { lte: +column.filterValues.lt - 1 }
              };
            }
          }
        });

      axios({
        url: process.env.VUE_APP_API_BASE_URL + "/network/app/products",
        method: "POST",
        signal: signal,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          product_type: this.productType,
          filters: filterParams,
          pagination: {
            per_page: dataTableState.pagination.rowsPerPage,
            page: dataTableState.pagination.page
          },
          sorting: sortParams,
          options: optionParams
        })
      })
        .then(response => {
          this.products = response.data.data.results;

          let totalRows = parseInt(response.data.data.totals.results);
          this.pagination.rowsNumber = totalRows > 10000 ? 10000 : totalRows;
          this.pagination.totalRows = totalRows;

          this.dimensionFilterOptionsByColumn = {};
          let facetsByColumn = response.data.metadata.facets;
          Object.keys(facetsByColumn).forEach(column => {
            this.dimensionFilterOptionsByColumn[column] = facetsByColumn[column]
              .filter(f => f.key.toString().length > 0)
              .map(facet => ({
                label: facet.key,
                value: facet.key
              }));
          });

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    },
    getColumnsForProductType() {
      switch (this.productType) {
        default:
          return [
            /*{
              name: "product_id",
              label: "Product ID",
              field: "product_id",
              type: "dimension",
              sortable: true,
              filterable: false
            },*/
            {
              name: "advertiser_account_id",
              label: "Account ID",
              field: "advertiser_account_id",
              type: "dimension",
              visible: false
            },
            {
              name: "advertiser_account_name",
              label: "Account",
              field: "advertiser_account_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "inventory_source_id",
              label: "Source ID",
              field: "inventory_source_id",
              type: "dimension",
              visible: false
            },
            {
              name: "inventory_source_name",
              label: "Source",
              field: "inventory_source_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "bid_value",
              label: "Bid Value",
              field: "bid_value",
              type: "dimension",
              sortable: true,
              filterable: false
            },
            {
              name: "title",
              label: "Title",
              field: "title",
              format: val =>
                val.length > 30 ? val.substr(0, 30) + "..." : val,
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "postal_code",
              label: "Postal Code",
              field: "postal_code",
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "destination_url_raw",
              label: "Destination URL",
              field: "destination_url_raw",
              hideField: true,
              sortable: false,
              filterable: false
            }
          ];
        case "vehicles":
          return [
            /*{
              name: "product_id",
              label: "Product ID",
              field: "product_id",
              type: "dimension",
              sortable: true,
              filterable: false
            },*/
            {
              name: "advertiser_account_id",
              label: "Account ID",
              field: "advertiser_account_id",
              type: "dimension",
              visible: false,
              filterValues: {
                in: this.$route.query.advertiser_account_id
                  ? [this.$route.query.advertiser_account_id]
                  : [],
                notIn: [],
                gt: "",
                lt: ""
              }
            },
            {
              name: "advertiser_account_name",
              label: "Account",
              field: "advertiser_account_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "inventory_source_id",
              label: "Source ID",
              field: "inventory_source_id",
              type: "dimension",
              visible: false,
              filterValues: {
                in: this.$route.query.inventory_source_id
                  ? [this.$route.query.inventory_source_id]
                  : [],
                notIn: [],
                gt: "",
                lt: ""
              }
            },
            {
              name: "inventory_source_name",
              label: "Source",
              field: "inventory_source_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "bid_value",
              label: "Bid Value",
              field: "bid_value",
              type: "dimension",
              sortable: true,
              filterable: false
            },
            {
              name: "image",
              label: "Image",
              hideField: true,
              isComponentField: true,
              field: row => ({
                component: QImg,
                componentProperties: {
                  src: row.images[0],
                  fit: "contain",
                  width: "70px",
                  style: "width: 70px; height: 50px;"
                },
                componentBody: ""
              }),
              sortable: false,
              type: "dimension",
              filterable: false
            },
            {
              name: "image_count",
              label: "Image Count",
              field: row => row.images.length,
              sortable: true,
              filterable: false,
              type: "metric",
              metricType: "number",
              visible: false
            },
            {
              name: "vin",
              label: "VIN",
              field: "vin",
              sortable: true,
              filterable: false
            },
            {
              name: "year",
              label: "Year",
              field: "year",
              sortable: true,
              type: "metric",
              metricType: "year"
            },
            {
              name: "make_raw",
              label: "Make",
              field: "make_raw",
              sortable: true,
              type: "dimension"
            },
            {
              name: "model_raw",
              label: "Model",
              field: "model_raw",
              sortable: true,
              type: "dimension"
            },
            {
              name: "trim",
              label: "Trim",
              format: row => (row.trim.length > 0 ? row.trim : "-"),
              sortable: false,
              filterable: false,
              type: "dimension",
              visible: false
            },
            {
              name: "body_type_raw",
              label: "Body Type",
              field: "body_type_raw",
              sortable: true,
              type: "dimension"
            },
            {
              name: "condition",
              label: "Condition",
              field: "condition",
              sortable: true,
              type: "dimension"
            },
            {
              name: "price",
              label: "Price",
              field: "price",
              format: val =>
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                }).format(val),
              sortable: true,
              type: "metric",
              metricType: "number"
            },
            {
              name: "mileage",
              label: "Mileage",
              field: "mileage",
              format: val => this.numberFormatter(val),
              sortable: true,
              type: "metric",
              metricType: "number"
            },
            {
              name: "drivetrain_raw",
              label: "Drivetrain",
              field: "drivetrain_raw",
              format: val => (val.length > 0 ? val : "-"),
              sortable: true,
              type: "dimension",
              visible: false
            },
            {
              name: "transmission_raw",
              label: "Transmission",
              field: "transmission_raw",
              sortable: true,
              type: "dimension",
              visible: false
            },
            {
              name: "engine_type",
              label: "Engine Type",
              field: "engine_type",
              sortable: true,
              type: "dimension",
              visible: false
            },
            {
              name: "fuel_type",
              label: "Fuel Type",
              field: "fuel_type",
              sortable: true,
              type: "dimension",
              visible: false
            },
            {
              name: "exterior_color_raw",
              label: "Ext. Color",
              field: "exterior_color",
              sortable: true,
              type: "dimension"
            },
            {
              name: "interior_color_raw",
              label: "Int. Color",
              field: "interior_color",
              sortable: true,
              filterable: false,
              type: "dimension",
              visible: false
            },
            {
              name: "destination_url_raw",
              label: "Destination URL",
              field: "destination_url_raw",
              hideField: true,
              sortable: false,
              filterable: false
            },
            {
              name: "dealer_id",
              label: "Dealer ID",
              field: row => row.dealer.id,
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "dealer_name",
              label: "Dealer",
              field: row => row.dealer.name,
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            /*{
              name: "dealer_city",
              label: "City",
              field: row => row.dealer.city,
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "dealer_state",
              label: "State",
              field: row => row.dealer.state,
              sortable: true,
              type: "dimension"
            },*/
            {
              name: "postal_code",
              label: "Postal Code",
              field: row => row.postal_code,
              sortable: true,
              type: "dimension"
            }
          ];
        case "jobs":
          return [
            /*{
              name: "product_id",
              label: "Product ID",
              field: "product_id",
              type: "dimension",
              sortable: true,
              filterable: false
            },*/
            {
              name: "advertiser_account_id",
              label: "Account ID",
              field: "advertiser_account_id",
              type: "dimension",
              visible: false
            },
            {
              name: "advertiser_account_name",
              label: "Account",
              field: "advertiser_account_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "inventory_source_id",
              label: "Source ID",
              field: "inventory_source_id",
              type: "dimension",
              visible: false
            },
            {
              name: "inventory_source_name",
              label: "Source",
              field: "inventory_source_name",
              hideField: true,
              type: "dimension"
            },
            {
              name: "bid_value",
              label: "Bid Value",
              field: "bid_value",
              type: "dimension",
              sortable: true,
              filterable: false
            },
            {
              name: "soc_code",
              label: "SOC Code",
              field: "soc_code",
              sortable: true,
              filterable: true,
              type: "dimension"
            },
            {
              name: "soc_title",
              label: "SOC Title",
              field: "soc_title",
              format: val =>
                val.length > 30 ? val.substr(0, 30) + "..." : val,
              sortable: true,
              filterable: true,
              type: "dimension"
            },
            {
              name: "title",
              label: "Title",
              field: "title",
              format: val =>
                val.length > 30 ? val.substr(0, 30) + "..." : val,
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "category",
              label: "Category",
              field: "category",
              format: val => (!val ? "-" : val),
              sortable: true,
              filterable: true,
              type: "dimension"
            },
            {
              name: "company",
              label: "Company",
              field: "company",
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "logo_image_url",
              label: "Logo Image",
              hideField: true,
              isComponentField: true,
              field: row => ({
                component: QImg,
                componentProperties: {
                  src: row.logo_image_url,
                  fit: "contain",
                  width: "70px",
                  style: "width: 70px; height: 50px;"
                },
                componentBody: ""
              }),
              sortable: false,
              type: "dimension",
              filterable: false
            },
            {
              name: "city",
              label: "City",
              field: "city",
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "state",
              label: "State",
              field: "state",
              sortable: true,
              filterable: true,
              type: "dimension"
            },
            {
              name: "postal_code",
              label: "Postal Code",
              field: "postal_code",
              sortable: true,
              filterable: false,
              type: "dimension"
            },
            {
              name: "destination_url_raw",
              label: "Destination URL",
              field: "destination_url_raw",
              hideField: true,
              sortable: false,
              filterable: false
            }
          ];
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
